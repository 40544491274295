import { useState, useEffect } from "react";

import { ReferralService } from "../services/ReferralService";

import { Button } from 'primereact/button';

import CashConfigurator from "./CashConfigurator";
import { SchoolSelector } from "./SchoolSelector";

export default function PaymentOptions(props)
{
    const [hasCashDef, setHasCashDef] = useState(false);
    const [cashMethod, setCashMethod] = useState("");
    const [showCashMethodConfig, setShowCashMethodConfig] = useState(false);
    
    const [hasDonationDef, setHasDonationDef] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [showDonationConfig, setShowDonationMethodConfig] = useState(false);
    
    const [user, setUser] = useState({});

    useEffect(async () => {
        setUser(props.user);
        setHasCashDef(ReferralService.hasCashMethodDefinition(props.user));
        setCashMethod(ReferralService.getCashMethodDefinition(props.user));
        setSchoolName(ReferralService.getSchoolName(props.user));
        
    }, []);

   return (<>
        <div className="payment-options">
            <div className="text">In order to get paid on time, please enter your payment method and account details.</div>
            <div className="card green">
                <div className="card-header">
                    <div className="card-title">Get Paid</div>
                    <img alt="Cash" src="/assets/layout/images/icon-hand-cash.png"/>
                </div>
                { hasCashDef && <div className="card-subtitle">Via <span>{cashMethod}</span></div> }
                <div className="buttons">    
                    <Button label={hasCashDef ? "Change Payment Method" : "Select Payment Method"} className="button" onClick={() => setShowCashMethodConfig(true)} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>
            { false &&
            <div className="card yellow">
                <div className="card-header">
                    <div className="card-title">Donate</div>
                    <img alt="Cash" src="/assets/layout/images/icon-hand-donation.png"/>
                </div>
                { schoolName !== "" && <div className="card-subtitle">To <span>{schoolName}</span></div>}
                <div className="buttons">    
                    <Button label={ schoolName !== "" ? "Change Public School" : "Select Public School"} className="button" onClick={() => setShowDonationMethodConfig(true)} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>}
        </div>
        { 
            showCashMethodConfig &&
            <CashConfigurator user={props.user} onUpdate={props.onUserUpdate} onHide={() => setShowCashMethodConfig(false)}/>
        }
        {
            showDonationConfig &&
            <SchoolSelector user={props.user} onUpdate={props.onUserUpdate} onHide={() => setShowDonationMethodConfig(false)}/>
        }
    </>);
}