import { useState, useRef } from "react";
import { withRouter, useHistory } from "react-router";

import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { ProgressBar } from "primereact/progressbar";
import { Password } from 'primereact/password';

import Header from "../components/Header";
import OnboardingMenu from "../components/OnboardingMenu";
import { SignUpService } from "../services/SignUpService";
import { AuthorizationService } from "../services/AuthorizationService";

function CreatePassword(props){

    const history = useHistory();
    const toast = useRef(null);

    const [processing, setProcessing] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const savePassword = async () => {
        if(password !== passwordConfirmation)
        {
            toast.current.show({severity:'error', summary: 'Invalid password', detail: "Password confirmation does not match", life: 3000});
        }
        else {
            try {
                setProcessing(true);
                const authorizationService = new AuthorizationService();
                const user = authorizationService.loggedInUser;
                const service = new SignUpService();
                const updatedUser = await service.savePassword(password, user.energyConsultant);
                setProcessing(false);
                props.onSignedUp(updatedUser);
            } 
            catch (error) 
            {
                setProcessing(false);
                console.log(error);
                if(error.code && error.code === 400){
                    toast.current.show({severity:'error', summary: 'Invalid password', detail: "Password is not complex enough. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters.", life: 3000});
                }
                else {
                    toast.current.show({severity:'error', summary: 'Ooops...', detail: error.message || error.toString(), life: 3000});
                }
            }
        }
    }

    
    return <>
        <div className="split-screen-background">
            <div className="left"></div>
            <div className="right"></div>
        </div>

        <Toast ref={toast} />


        <div className="screen onboarding create-password">

            <Header/>

            <div className="screen-segment left">
                <OnboardingMenu step={4}/>
            </div>

            <div className="steps-progreess-bar" style={{width: '100%'}}></div>

            <div className="screen-segment right">
                <div className="form">
                    
                    <div className="form-title">
                        Almost there! Now, create your password to access your dashboard and enter your payment information. 
                    </div>

                    <div className="form-fields">
                        <div className="form-field">
                            <Password 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder="Enter Password"
                                style={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                                promptLabel="Password must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                weakLabel="Password is too weak. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                mediumLabel="Password is not complex enough yet. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                strongLabel="We are good to go."
                                toggleMask={true}
                            />
                        </div>
                        <div className="form-field">
                            <Password 
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                placeholder="Confirm Password" 
                                feedback={false}
                                toggleMask={true}
                                style={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button disabled={password === "" || passwordConfirmation === ""} label="Login" className="button" onClick={savePassword} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>

                    { (processing) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                </div>
            </div>

        </div>
    </>;
}

export default withRouter(CreatePassword);