import { useState, useRef, useEffect } from "react";
import { withRouter, useHistory } from "react-router";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { ProgressBar } from "primereact/progressbar";

import Header from "../components/Header";
import OnboardingMenu from "../components/OnboardingMenu";
import { SignUpService } from "../services/SignUpService";
import { AuthorizationService } from "../services/AuthorizationService";

function VerifyEmail(props){

    const history = useHistory();

    const toast = useRef(null);

    const [processing, setProcessing] = useState(false);
    const [code, setCode] = useState("");
    const [user, setUser] = useState({});
    const [skiped, setSkiped] = useState(true);

    useEffect(async () => {        
        const authorizationService = new AuthorizationService();
        setUser(authorizationService.loggedInUser);

        if(window.location.search)
        {
            const parameters = window.location.search.split("?")[1].split("&").map(pstr => ({key: pstr.split("=")[0], value: pstr.split("=")[1]}));
            console.log(parameters);
            if(parameters.find(p => p.key === 'skip')){
                setSkiped(true);
            }
            else {
                setSkiped(false);
            }
            
        }
        else {
            setSkiped(false);
        }

    }, []);

    const sendCode = async () => {
        try {
            setProcessing(true);
            const service = new SignUpService();
            await service.sendToken(user.name, user.email);
            setProcessing(false);
            history.push("/verify-email") 
        } 
        catch (error) 
        {
            setProcessing(false);
            console.log(error);
            toast.current.show({severity:'error', summary: 'Ooops...', detail: error.message || error.toString(), life: 3000});
        }
        
    }

    const checkCode = async () => {
        
        try {
            setProcessing(true);
            const service = new SignUpService();
            await service.checkToken(code);
            setProcessing(false);
            history.push("/create-password");
        } 
        catch (error) 
        {
            console.log(error);
            setProcessing(false);
            if(error.code && error.code === 400){
                toast.current.show({severity:'error', summary: 'Ooops...', detail: "Invalid verification code", life: 3000});
            }
            else {
                toast.current.show({severity:'error', summary: 'Ooops...', detail: error.message || error.toString(), life: 3000});
            }
        }
    }

    return <>
        <div className="split-screen-background">
            <div className="left"></div>
            <div className="right"></div>
        </div>

        <Toast ref={toast} />

        <div className="screen onboarding verify-email">

            <Header/>

            <div className="screen-segment left">
                <OnboardingMenu step={3}/>
            </div>

            <div className="steps-progreess-bar" style={{width: '66%'}}></div>

            <div className="screen-segment right">
                <div className="form">
                    <div className="form-title">
                        { !skiped && <>
                        <span>Your Referrals were submitted successfully, Thank You!</span>
                        <br/><br/>
                        </>}
                        To create your account, we need to verify your e-mail address. We just sent an e-mail to {user.email} with a verification code. Please enter your code below. 
                    </div>

                    <div className="form-field">
                    <InputText
                                value={code}
                                placeholder="XXXX" 
                                onChange={(e) => setCode(e.target.value)}
                            />
                    </div>

                    <div className="buttons">
                        <Button disabled={code === ""} label="Continue" className="button" onClick={checkCode} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>

                    <div className="send-again">Didn't receive the code? <span onClick={sendCode}>Send again</span>.</div>
                    { (processing) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                </div>
            </div>

        </div>
    </>;
}

export default withRouter(VerifyEmail);