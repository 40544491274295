import { useState, useEffect } from "react";

import * as _ from 'lodash';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { ReferralService } from "../services/ReferralService";
import { AccountService } from "../services/AccountService";

export default function CashConfigurator(props){

    const [user, setUser] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(async () => {
        setUser(props.user);
        
    }, []);

    const isDataValid = () => { 

        if((user.paymentConfig.config.preferred || "") === ""){
            return false;
        }

        if((user.paymentConfig.config.preferred || "") !== ""){

            if((user.paymentConfig.config.preferred || "") === "Paypal") {
                if((user.paymentConfig.config.paypal.id_type || "") === ""){
                    return false;
                }

                if((user.paymentConfig.config.paypal.id || "") === ""){
                    return false;
                }
            }

            if((user.paymentConfig.config.preferred || "") === "Venmo") {
                if((user.paymentConfig.config.venmo.id || "") === ""){
                    return false;
                }
            }
            
        }

        return true;
    }

    const save = async () => {
        try 
        {
            setSubmitted(true);
            if(isDataValid()){
                setSaving(true);
                const service = new AccountService();
                await service.updateProfile(user);
                setSaving(false);
                props.onUpdate(user);
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            alert(error.message ? error.message : error);
        }
    }

    const updateValue = (fieldName, value) => {
        const _user = Object.assign({}, user) 
        _.set(_user, fieldName, value);
        setUser(_user);
        //setShowSuccessMessage(false);
        setSubmitted(false);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const paymentProviders = [
        {label: 'Paypal', value: 'Paypal'},
        {label: 'Venmo', value: 'Venmo'}
    ];

    const paypalIdTypes = [
        {label: 'Email', value: 'EMAIL'},
        {label: 'Phone', value: 'PHONE'},
        {label: 'Paypal ID', value: 'PAYPAL_ID'}
    ];

    const method = ReferralService.getCashMethodDefinition(user);
    return (<>
        <Dialog
                className="dialog"
                header={<div className="payment-options-dialog-title">Your Payment Method</div>}
                visible={true}
                style={{width: window.innerWidth > 1000 ? '40vw' : '95vw'}}
                onHide={props.onHide}
                >
                <>
                    <div className="payment-options-form-item">
                        <div className="payment-options-form-item-label">
                            Provider:
                        </div>
                        <div className="payment-options-form-item-field">
                            <Dropdown style={{width: '100%'}} value={method} options={paymentProviders} onChange={(e) => updateValue('paymentConfig.config.preferred', e.value)} placeholder="Paypal or Venmo"/>
                            { submitted && method === "" && <div className="user-error">Provider is mandatory</div>}
                        </div>
                    </div>
                    
                    { 
                        method === "Paypal" && 
                        <div className="payment-options-form-item">
                            <div className="payment-options-form-item-label">
                                ID Type:
                            </div>
                            <div className="payment-options-form-item-field">
                                <Dropdown style={{width: '100%'}} value={user.paymentConfig.config.paypal.id_type || ""} options={paypalIdTypes} onChange={(e) => updateValue('paymentConfig.config.paypal.id_type', e.value)} placeholder="Email, Phone or Paypal ID"/>
                                { submitted && (user.paymentConfig.config.paypal.id_type || "") === "" && <div className="user-error">Paypal ID Type is mandatory</div>}
                            </div>
                        </div>
                    }

                    <div className="payment-options-form-item">
                        <div className="payment-options-form-item-label">
                            { method === "Paypal" && (user.paymentConfig.config.paypal.id_type || "") === "EMAIL" && <>Email</> }
                            { method === "Paypal" && (user.paymentConfig.config.paypal.id_type || "") === "PHONE" && <>Phone:</> }
                            { method === "Paypal" && (user.paymentConfig.config.paypal.id_type || "") === "PAYPAL_ID" && <>ID:</> }
                            { method === "Venmo" && <>Phone:</> }
                        </div>
                        <div className="payment-options-form-item-field">
                                { method === "Paypal" && (user.paymentConfig.config.paypal.id_type || "") === "" && <>&nbsp;</>}
                                { method === "Paypal" && ((user.paymentConfig.config.paypal.id_type || "") === "EMAIL" || (user.paymentConfig.config.paypal.id_type || "") === "PAYPAL_ID") && 
                                    <>
                                        <InputText style={{width: '100%'}} value={user.paymentConfig.config.paypal.id || ""} onChange={(e) => updateValue('paymentConfig.config.paypal.id', e.target.value)} />
                                        { submitted && (user.paymentConfig.config.paypal.id || "") === "" && <div className="user-error">Paypal ID is mandatory</div>}
                                    </>
                                }
                                { method === "Paypal" && (user.paymentConfig.config.paypal.id_type || "") === "PHONE" && 
                                    <>
                                        <InputMask style={{width: '100%'}} autoClear={false} mask="(999) 999-9999" value={user.paymentConfig.config.paypal.id || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('paymentConfig.config.paypal.id', cleanPhone(e.value))}></InputMask>
                                        { submitted && (user.paymentConfig.config.paypal.id || "") === "" && <div className="user-error">Paypal ID is mandatory</div>}
                                    </>
                                }
                                { method === "Venmo" && 
                                    <>
                                        <InputMask style={{width: '100%'}} autoClear={false} mask="(999) 999-9999" value={user.paymentConfig.config.venmo.id || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('paymentConfig.config.venmo.id', cleanPhone(e.value))}></InputMask>
                                        { submitted && (user.paymentConfig.config.venmo.id || "") === "" && <div className="user-error">Venmo ID is mandatory</div>}
                                    </>
                                }
                        </div>
                    </div>
                    <div className="payment-options-buttons">    
                        <Button disabled={saving} label="Save" className="button" onClick={save}  icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                </>
            </Dialog>
    </>);
}