import { BaseService } from './_BaseService';

export class SignUpService extends BaseService {

    sendToken = async (name, email) => {
        return await this.post(`send-token`, { name, email });
    }

    checkToken = async (token) => {
        return await this.post(`check-token`, { token });
    }

    savePassword = async (password, energyConsultant) => {
        return await this.post(`create-password${ energyConsultant ? `?ec=${energyConsultant}`:''}`, { password });
    }
}