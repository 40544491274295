export default function OnboardingMenu(props){
    return (
        <>
        <div className="steps">
            <div className={`step${ props.step === 1 ? " active" : ""}`}>
                <div className="step-title">Step 1</div>
                <div className="step-label">Sales Person</div>
                <div className="step-text">Select sales person</div>
                <div className="step-divider">
                    <img src="/assets/layout/images/bg-steps-divider.png"/>
                </div>
            </div>
            <div className={`step${ props.step === 2 ? " active" : ""}`}>
                <div className="step-title">Step 2</div>
                <div className="step-label">Add Your Referrals</div>
                <div className="step-text">Add Your Friends</div>
                <div className="step-divider">
                    <img src="/assets/layout/images/bg-steps-divider.png"/>
                </div>
            </div>
            <div className={`step${ props.step === 3 ? " active" : ""}`}>
                <div className="step-title">Step 3</div>
                <div className="step-label">Verify E-mail</div>
                <div className="step-text">Enter Your Code</div>
                <div className="step-divider">
                    <img src="/assets/layout/images/bg-steps-divider.png"/>
                </div>
            </div>
            <div className={`step${ props.step === 4 ? " active" : ""}`}>
                <div className="step-title">Step 4</div>
                <div className="step-label">Login</div>
                <div className="step-text">Create Your Password</div>
                <div className="step-divider">
                    <img src="/assets/layout/images/bg-steps-divider.png"/>
                </div>
            </div>
        </div>
        </>
    )
}