import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from "primereact/dialog";
import { DataView } from 'primereact/dataview';
import * as _ from 'lodash';

import states from '../assets/us_states.json';
import { SchoolService } from '../services/SchoolService';
import { AccountService } from '../services/AccountService';

export function SchoolSelector(props) {

    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [selectedState, setSelectedState] = useState('');
    const [schools, setSchools] = useState([]);
    const service = new SchoolService();
    const [saving, setSaving] = useState(false);
     
    const selectState = async (id) => {
        setSelectedState(id);
        await search({state: id, key: searchKey.length >= 4 ? searchKey : ''});
    }

    const search = async (config) => {
        setLoading(true);
        const result = await service.search(config);
        setTotalRecords(result.total);
        setSchools(result.items);
        setLoading(false);
    }

    const selectSchool = async (school) => {

        const _user = Object.assign({}, props.user);
        _.set(_user, 'paymentConfig.config.school', school);
        setSaving(true);
        const service = new AccountService();
        await service.updateProfile(_user);
        setSaving(false);
        props.onUpdate(_user);
    }

    const updateSearchKey = async (key) => {
        setSearchKey(key);
        if(key.length >= 4){
            await search({state: selectedState, key: key.length >= 4 ? key : '', page: 1});
        }
    }

    const schoolOptionTemplate = (option) => {
        if (option) {
            return (
                <div style={{width: '100%', padding: '12px', paddingLeft: '0px', cursor: 'pointer'}} onClick={() => selectSchool(option)}>
                    <b>{option.name}</b><br/>
                    <i>{option.address}, {option.city} - {option.postal_code}</i>
                </div>
            );
        }
    
        return (
            <span>&nbsp;</span>
        );
    }

    const onPage = async (event) => {
        setFirst(event.first);
        await search({state: selectedState, key: searchKey.length >= 4 ? searchKey : '', page: event.originalEvent.page + 1});
    }

    return <>
        
        <Dialog
                className="dialog"
                header={<div className="payment-options-dialog-title">Your Donation Preference</div>}
                visible={true}
                style={{width: window.innerWidth > 1000 ? '40vw' : '95vw'}}
                onHide={props.onHide}
                >
                <div style={{height: '500px'}}>

                <div className="payment-options-form-item">
                    <div className="payment-options-form-item-label">State</div>
                    <div className="payment-options-form-item-field">
                        <Dropdown style={{width: '100%'}} value={selectedState} options={states.map(s => ({label: `${s.abbreviation} - ${s.name}`, value: s.abbreviation}))} onChange={(e) =>selectState(e.value)} placeholder=""/>
                    </div>
                </div>
                <div className="payment-options-form-item">
                    <div className="payment-options-form-item-label">Name</div>
                    <div className="payment-options-form-item-field">
                        <InputText style={{width: '100%'}} value={searchKey} onChange={(e) => updateSearchKey(e.target.value)} />
                    </div>
                </div>
                { selectedState !== "" && <>
                    <div className="payment-options-form-item-fullline"><b>{totalRecords}</b> schools found. Please select one.</div>
                    <div className="payment-options-form-item-fullline">
                        <DataView value={schools}
                        itemTemplate={schoolOptionTemplate} lazy paginator={totalRecords !== 0} paginatorPosition={'bottom'} rows={5}
                        totalRecords={totalRecords} first={first} onPage={onPage} loading={loading} 
                        pageLinkSize={4}
                        emptyMessage={"No schools found"}/>
            
            
                    </div>
                </>}
            </div>
            
        </Dialog>
    </>;
}