import { BaseService } from './_BaseService';

export class PaymentService extends BaseService {

    static STATUS = {
        "PENDING_APPROVAL": "Pending Approval",
        "PENDING_CONFIG": "Pending Payment Preference",
        "PENDING_PAYMENT": "Processing Payment",
        "PAID": "Paid",
    }

    static getStatusLabel = (status) => {

        return PaymentService.STATUS[status] || "-";

    }
    
    list = async () => {
        return await this.get(`payments`);
    }

    getPayment = async (id) => {
        return await this.get(`payments/${id}`);
    }

    save = async (payload) => {
        return await this.post(`payments/${payload.id}`,payload);
    }
}