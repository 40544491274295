import { useState, useRef, useEffect } from "react";
import { withRouter, useHistory } from "react-router";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { ProgressBar } from "primereact/progressbar";
import { InputMask } from 'primereact/inputmask';

import Header from "../components/Header";

function PartnerThankYou(props){

    const history = useHistory();
    
    const backToHome = async () => {
        history.push(`/partners/${ props.school ? `${props.school}` : ""}`); 
    }
    return <>
        
        <div className="screen partner-thank-you">

            <Header darkHeader={true}/>

            <div className="screen-segment left">
                <div className="title">Thank You!</div>
                <div className="text">Thank you for sharing the power of smarter, cleaner energy​. We will reach out to schedule a meeting.</div>
                <div className="buttons">
                    <Button label="Back to Home Page" className="button" onClick={backToHome} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>

        </div>
    </>;
}

export default withRouter(PartnerThankYou);