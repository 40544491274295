import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';

import * as _ from 'lodash';
import * as uuid from 'uuid';

import { SchoolSelector } from '../components/SchoolSelector';
import { PaymentService } from '../services/PaymentService';
import { ReferralService } from '../services/ReferralService';

export function Payment(props) {

    let history = useHistory();
    let { id } = useParams();

    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState(props.user);
    const [payment, setPayment] = useState({data:{config:{paypal: {}, venmo: {}}}});
    const [referral, setReferral] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(async () => {
        const paymentService = new PaymentService();
        const paymentObj = await paymentService.getPayment(id);
        
        if(!paymentObj.data.config){
            paymentObj.data.config = {paypal: {}, venmo: {}};
        }

        if(!paymentObj.data.config.paypal){
            paymentObj.data.config.paypal = {};
        }

        if(!paymentObj.data.config.venmo){
            paymentObj.data.config.venmo = {};
        }

        setPayment(paymentObj);

        setReferral(props.user.referrals.find(r => r.id === paymentObj.referral_id));
        setLoading(false);
    }, []);
    
    const isDataValid = () => {

        if((payment.data.config.option || "donation") === "cash") 
        {
            if((payment.data.config.preferred || "") === ""){
                return false;
            }
            
            if((payment.data.config.preferred || "") === "Paypal") {
                if((payment.data.config.paypal.id_type || "") === ""){
                    return false;
                }

                if((payment.data.config.paypal.id || "") === ""){
                    return false;
                }
            }

            if((payment.data.config.preferred || "") === "Venmo") {
                if((payment.data.config.venmo.id || "") === ""){
                    return false;
                }
            }
        }

        if((payment.data.config.option || "donation") === "donation") 
        {
            if(!payment.data.config.school){
                return false;
            }
        }

        return true;
    }

    const save = async () => {
        try 
        {
            setShowSuccessMessage(false);
            setSubmitted(true);
            if(isDataValid()){
                setSaving(true);
                payment.status = "PENDING_PAYMENT";
                const service = new PaymentService();
                await service.save(payment);
                setSaving(false);
                setShowSuccessMessage(true);
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            alert(error.message ? error.message : error);
        }
    }

    const updateValue = (fieldName, value) => {
        const _payment = Object.assign({}, payment);
        _.set(_payment, fieldName, value);
        setPayment(_payment);
        setShowSuccessMessage(false);
        setSubmitted(false);
        console.log(_payment);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const paymentProviders = [
        {label: 'Paypal', value: 'Paypal'},
        {label: 'Venmo', value: 'Venmo'}
    ];

    const paypalIdTypes = [
        {label: 'Email', value: 'EMAIL'},
        {label: 'Phone', value: 'PHONE'},
        {label: 'Paypal ID', value: 'PAYPAL_ID'}
    ];

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      const editable = false;// ["PENDING_CONFIG","PENDING_PAYMENT"].indexOf(payment.status || "") >= 0;


    return <div className="screen home">
    
            <div className="title">Payment</div>
            <div className="form-box">
                <div id="profile-small" className="p-grid">
                    <div className="p-col-12 column-title">Referral</div>
                    <div className="p-col-12 column-subtitle">
                        {referral.first_name} {referral.last_name}
                    </div>
                    <div className="p-col-12 column-title">Payment Type</div>
                    <div className="p-col-12 column-subtitle">
                        {payment.type === "MEETING" ? "Appointment" : "Activation"}
                    </div>
                    <div className="p-col-12 column-title">Status</div>
                    <div className="p-col-12 column-subtitle">
                        { payment.type === "MEETING" ? ReferralService.getAppointmentStatus(referral, [payment]) :  ReferralService.getActivationStatus(referral, [payment])}
                    </div>
                    <div className="p-col-12 column-title">Amount</div>
                    <div className="p-col-12 column-subtitle">
                        {payment.data.total_amount ? currencyFormatter.format(payment.data.total_amount) : "-"}
                    </div>
                </div>
                <div id="profile-large" className="p-grid">
                    <div className="p-col-3 column-title">Referral</div>
                    <div className="p-col-3 column-title">Payment Type</div>
                    <div className="p-col-3 column-title">Status</div>
                    <div className="p-col-3 column-title">Amount</div>
                    <div className="p-col-3 column-subtitle">
                        {referral.first_name} {referral.last_name}
                    </div>
                    <div className="p-col-3 column-subtitle">
                        {payment.type === "MEETING" ? "Appointment" : "Activation"}
                    </div>
                    <div className="p-col-3 column-subtitle">
                        { payment.type === "MEETING" ? ReferralService.getAppointmentStatus(referral, [payment]) :  ReferralService.getActivationStatus(referral, [payment])}
                    </div>
                    <div className="p-col-3 column-subtitle">
                        {payment.data.total_amount ? currencyFormatter.format(payment.data.total_amount) : "-"}
                    </div>
                </div>
                <div className="p-grid">
                    
                    <div className="p-col-12 section-title">My Payment Option</div>
                    { editable && <>
                        <div className="p-col-12 section-subtitle">Select how you would like to be rewarded.</div>
                        <div id="payment-options-small" className="p-col-12 p-grid">
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-8 column-title">Donate to a Public School</div>
                                    <div className="p-col-4 column-title" style={{textAlign: 'right'}}>
                                        <Checkbox inputId="cbDonation" onChange={e => updateValue('data.config.option', e.checked ? "donation" : "cash")} checked={(payment.data.config.option || "donation") === "donation"}></Checkbox>
                                        <label htmlFor="cbDonation" className="p-checkbox-label"> Preferred</label>
                                    </div>    
                                    <div className="p-col-12 section-subtitle">Send your reward to help a public elementary, middle or high school.</div>
                                    <SchoolSelector school={payment.data.config.school} onUpdateSchool={(school) => updateValue('data.config.school', school)} />
                                    { submitted && (payment.data.config.option || "donation") === "donation" && !payment.data.config.school && <div className="user-error">Please select a school</div>}
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-8 column-title">Get Paid</div>
                                    <div className="p-col-4 column-title" style={{textAlign: 'right'}}>
                                        <Checkbox inputId="cbCash" onChange={e => updateValue('data.config.option', e.checked ? "cash" : "donation")} checked={(payment.data.config.option || "donation") === "cash"}></Checkbox>
                                        <label htmlFor="cbCash" className="p-checkbox-label"> Preferred</label>
                                    </div>
                                    <div className="p-col-12 section-subtitle">Choose from PayPal or Venmo to receive your cash reward.</div>
                                    
                                    <div className={`p-col-3 section-subtitle`}>Provider:</div>
                                    <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                        <Dropdown style={{width: '100%'}} value={payment.data.config.preferred || ""} options={paymentProviders} onChange={(e) => updateValue('data.config.preferred', e.value)} placeholder="Paypal or Venmo"/>
                                        { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.preferred || "") === "" && <div className="user-error">Provider is mandatory</div>}
                                    </div>

                                    { (payment.data.config.preferred || "") === "Paypal" && <div className={`p-col-3 section-subtitle`}>ID Type:</div>}
                                    { (payment.data.config.preferred || "") === "Paypal" && <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <Dropdown style={{width: '100%'}} value={payment.data.config.paypal.id_type || ""} options={paypalIdTypes} onChange={(e) => updateValue('data.config.paypal.id_type', e.value)} placeholder="Email, Phone or Paypal ID"/>
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.paypal.id_type || "") === "" && <div className="user-error">Paypal ID Type is mandatory</div>}
                                        </div>
                                    }

                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "EMAIL" && <div className="p-col-3 section-subtitle">Email:</div> }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PHONE" && <div className="p-col-3 section-subtitle">Phone:</div> }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PAYPAL_ID" && <div className="p-col-3 section-subtitle">ID:</div> }
                                    { (payment.data.config.preferred || "") === "Venmo" && <div className="p-col-3 section-subtitle">Phone:</div> }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "" && <div className="p-col-4">&nbsp;</div>}
                                    { (payment.data.config.preferred || "") === "Paypal" && ((payment.data.config.paypal.id_type || "") === "EMAIL" || (payment.data.config.paypal.id_type || "") === "PAYPAL_ID") && 
                                        <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <InputText style={{width: '100%'}} value={payment.data.config.paypal.id || ""} onChange={(e) => updateValue('data.config.paypal.id', e.target.value)} />
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.paypal.id || "") === "" && <div className="user-error">Paypal ID is mandatory</div>}
                                        </div>
                                    }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PHONE" && 
                                        <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <InputMask style={{width: '100%'}} autoClear={false} mask="(999) 999-9999" value={payment.data.config.paypal.id || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('data.config.paypal.id', cleanPhone(e.value))}></InputMask>
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.paypal.id || "") === "" && <div className="user-error">Paypal ID is mandatory</div>}
                                        </div>
                                    }
                                    { (payment.data.config.preferred || "") === "Venmo" && 
                                        <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <InputMask style={{width: '100%'}} autoClear={false} mask="(999) 999-9999" value={payment.data.config.venmo.id || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('data.config.venmo.id', cleanPhone(e.value))}></InputMask>
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.venmo.id || "") === "" && <div className="user-error">Venmo ID is mandatory</div>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="payment-options-large" className="p-col-12 p-grid">
                            <div className="p-col-5">
                                <div className="p-grid">
                                    <div className="p-col-8 column-title">Donate to a Public School</div>
                                    <div className="p-col-4 column-title" style={{textAlign: 'right'}}>
                                        <Checkbox inputId="cbDonation" onChange={e => updateValue('data.config.option', e.checked ? "donation" : "cash")} checked={(payment.data.config.option || "donation") === "donation"}></Checkbox>
                                        <label htmlFor="cbDonation" className="p-checkbox-label"> Preferred</label>
                                    </div>    
                                    <div className="p-col-12 section-subtitle">Send your reward to help a public elementary, middle or high school.</div>
                                    <SchoolSelector school={payment.data.config.school} onUpdateSchool={(school) => updateValue('data.config.school', school)} />
                                    { submitted && (payment.data.config.option || "donation") === "donation" && !payment.data.config.school && <div className="user-error">Please select a school</div>}
                                </div>
                            </div>
                            <div className="p-col-1">&nbsp;</div>
                            <div className="p-col-6">
                                <div className="p-grid">
                                    <div className="p-col-8 column-title">Get Paid</div>
                                    <div className="p-col-4 column-title" style={{textAlign: 'right'}}>
                                        <Checkbox inputId="cbCash" onChange={e => updateValue('data.config.option', e.checked ? "cash" : "donation")} checked={(payment.data.config.option || "donation") === "cash"}></Checkbox>
                                        <label htmlFor="cbCash" className="p-checkbox-label"> Preferred</label>
                                    </div>
                                    <div className="p-col-12 section-subtitle">Choose from PayPal or Venmo to receive your cash reward.</div>
                                    
                                    <div className={`p-col-3 section-subtitle`}>Provider:</div>
                                    <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                        <Dropdown style={{width: '100%'}} value={payment.data.config.preferred || ""} options={paymentProviders} onChange={(e) => updateValue('data.config.preferred', e.value)} placeholder="Paypal or Venmo"/>
                                        { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.preferred || "") === "" && <div className="user-error">Provider is mandatory</div>}
                                    </div>

                                    { (payment.data.config.preferred || "") === "Paypal" && <div className={`p-col-3 section-subtitle`}>ID Type:</div>}
                                    { (payment.data.config.preferred || "") === "Paypal" && <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <Dropdown style={{width: '100%'}} value={payment.data.config.paypal.id_type || ""} options={paypalIdTypes} onChange={(e) => updateValue('data.config.paypal.id_type', e.value)} placeholder="Email, Phone or Paypal ID"/>
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.paypal.id_type || "") === "" && <div className="user-error">Paypal ID Type is mandatory</div>}
                                        </div>
                                    }

                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "EMAIL" && <div className="p-col-3 section-subtitle">Email:</div> }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PHONE" && <div className="p-col-3 section-subtitle">Phone:</div> }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PAYPAL_ID" && <div className="p-col-3 section-subtitle">ID:</div> }
                                    { (payment.data.config.preferred || "") === "Venmo" && <div className="p-col-3 section-subtitle">Phone:</div> }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "" && <div className="p-col-4">&nbsp;</div>}
                                    { (payment.data.config.preferred || "") === "Paypal" && ((payment.data.config.paypal.id_type || "") === "EMAIL" || (payment.data.config.paypal.id_type || "") === "PAYPAL_ID") && 
                                        <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <InputText style={{width: '100%'}} value={payment.data.config.paypal.id || ""} onChange={(e) => updateValue('data.config.paypal.id', e.target.value)} />
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.paypal.id || "") === "" && <div className="user-error">Paypal ID is mandatory</div>}
                                        </div>
                                    }
                                    { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PHONE" && 
                                        <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <InputMask style={{width: '100%'}} autoClear={false} mask="(999) 999-9999" value={payment.data.config.paypal.id || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('data.config.paypal.id', cleanPhone(e.value))}></InputMask>
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.paypal.id || "") === "" && <div className="user-error">Paypal ID is mandatory</div>}
                                        </div>
                                    }
                                    { (payment.data.config.preferred || "") === "Venmo" && 
                                        <div className="p-col-9" style={{paddingLeft: '0px'}}>
                                            <InputMask style={{width: '100%'}} autoClear={false} mask="(999) 999-9999" value={payment.data.config.venmo.id || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('data.config.venmo.id', cleanPhone(e.value))}></InputMask>
                                            { submitted && (payment.data.config.option || "donation") === "cash" && (payment.data.config.venmo.id || "") === "" && <div className="user-error">Venmo ID is mandatory</div>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>}

                    { !editable && payment.data.config.option === "cash" && <>
                        <div className="p-col-12 column-title">Get Paid</div>
                        { (payment.data.config.preferred || "") === "Paypal" && 
                            <div className="p-col-12 section-subtitle">
                                Paypal: {payment.data.config.paypal.id}
                            </div>
                        }
                        { (payment.data.config.preferred || "") === "Venmo" && 
                            <div className="p-col-12 section-subtitle">
                                Venmo: {payment.data.config.venmo.id}
                            </div>
                        }
                        </>
                    }

                    { !editable && payment.data.config.option === "donation" && <>
                        <div className="p-col-12 column-title">Donate to a Public School</div>
                        <div className="p-col-12">
                            <div className="p-col-12 section-subtitle">
                                <b>{payment.data.config.school.name}</b>
                            </div>
                            <div className="p-col-12 section-subtitle">
                                <i>{payment.data.config.school.address}, {payment.data.config.school.city} - {payment.data.config.school.postal_code}</i>
                            </div>
                        </div>
                        </>
                    }

                    { false && <>
                        <div className={`p-col-${(payment.data.config.preferred || "") === "" ? '6' : '2'} column-subtitle`}>Provider</div>
                        { (payment.data.config.preferred || "") === "Paypal" && <div className={`p-col-${(payment.data.config.paypal.id_type || "") === "" ? '4' : '2'} column-subtitle`}>Paypal ID Type</div>}
                        { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "EMAIL" && <div className="p-col-2 column-subtitle">Paypal Email</div> }
                        { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PHONE" && <div className="p-col-2 column-subtitle">Paypal Phone</div> }
                        { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PAYPAL_ID" && <div className="p-col-2 column-subtitle">Paypal ID</div> }
                        { (payment.data.config.preferred || "") === "Venmo" && <div className="p-col-4 column-subtitle">Venmo Phone</div> }
                        <div className="p-col-1">&nbsp;</div>
                        <div className="p-col-5 column-subtitle">School</div>

                        <div className="p-col-2">
                            {(paymentProviders.find(i => i.value === payment.data.config.preferred) || {}).label}
                        </div>
                        { (payment.data.config.preferred || "") === "" && <div className="p-col-4">&nbsp;</div>}
                        { (payment.data.config.preferred || "") === "Paypal" && 
                            <div className="p-col-2">
                                {(paypalIdTypes.find(i => i.value === payment.data.config.paypal.id_type) || {}).label}
                            </div>
                        }
                        { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "" && <div className="p-col-2">&nbsp;</div>}
                        { (payment.data.config.preferred || "") === "Paypal" && ((payment.data.config.paypal.id_type || "") === "EMAIL" || (payment.data.config.paypal.id_type || "") === "PAYPAL_ID") && 
                            <div className="p-col-2">
                                {payment.data.config.paypal.id}
                            </div>
                        }
                        { (payment.data.config.preferred || "") === "Paypal" && (payment.data.config.paypal.id_type || "") === "PHONE" && 
                            <div className="p-col-2">
                                {payment.data.config.paypal.id}
                            </div>
                        }
                        { (payment.data.config.preferred || "") === "Venmo" && 
                            <div className="p-col-4">
                                {payment.data.config.venmo.id}
                            </div>
                        }

                        <div className="p-col-1">&nbsp;</div>
                        <div className="p-col-5">[under construction]</div>
                    
                    </>}
                    
                </div>
                <div className="buttons">    
                    { editable && <Button disabled={saving} label="Save Preferences" className="button" onClick={save} /> }
                    <Button disabled={saving} label="Back to My Referrals" className="button" onClick={() => history.push("/")} />
                </div>
                { showSuccessMessage && <div className="buttons"><div className="success-message">Preferences saved successfully</div></div>}
                { (saving || loading) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
            </div>

    </div>
}