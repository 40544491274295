import config from '../config.json';
import axios from 'axios';

const LOCAL_STORAGE_LOGGED_IN_USER_PROP = "loggedInUser";
const LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP = "AuthorizationToken";
const AUTHORIZATION_HEADER_NAME = "referral-program-authorization-token";

axios.interceptors.request.use(
    (req) => {    
        if(req.url.startsWith(config.BASE_URL)){
            const token = localStorage.getItem(LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP);
            req.headers['Authorization'] = token;
        }    
        return req;
    }, 
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if(response.config.url.startsWith(config.BASE_URL)){
            const token = response.headers[AUTHORIZATION_HEADER_NAME];
            if(token)
            {
                localStorage.setItem(LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP, token);
            }
        }
        return response;
    }, 
    (error) => {
        try 
        {
            if(error.response && error.response.status === 401)
            {
                const endpoint = error.response.config.url.split("/").reverse()[0];
                if(["login", "update-password"].indexOf(endpoint) < 0)
                {
                    localStorage.removeItem(LOCAL_STORAGE_LOGGED_IN_USER_PROP);
                    alert("Your session has expired. Please identify yourself again")
                    window.location.reload();
                }
            } 
        } 
        catch (error) 
        {
            console.log(error);
        }

        const errorObject = {
            code: error.response.status,
            message: ""
        }
        if(error && error.response && error.response.data && error.response.data.message)
        {
            errorObject.message = error.response.data.message;
        }
    
        return Promise.reject(errorObject);
    }
);

export class BaseService {
    constructor(){
        this.LOCAL_STORAGE_LOGGED_IN_USER_PROP = LOCAL_STORAGE_LOGGED_IN_USER_PROP;
        this.LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP = LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP;
        this.AUTHORIZATION_HEADER_NAME = AUTHORIZATION_HEADER_NAME;
    }

    get = async (url) => {
        return (await axios.get(`${config.BASE_URL}${url}`)).data;
    }

    post = async (url, data, headers) => {
        return (await axios.post(`${config.BASE_URL}${url}`, data, { headers: headers || {} })).data;
    }

    update = async (url, data) => {
        return (await axios.put(`${config.BASE_URL}${url}${url}`, data)).data;
    }
    
    delete = async (url, data) => {
        return (await axios.delete(`${config.BASE_URL}${url}`, data)).data;
    }
}