import React, { useState, useEffect } from 'react';

import * as _ from 'lodash';

import {  PaymentService} from '../services/PaymentService';
import Header from '../components/Header';
import Tabs from '../components/Tabs';


export function Profile(props) {

    const [payments, setPayments] = useState([]);

    useEffect(async () => {
        const paymentService = new PaymentService();
        const paymentList = await paymentService.list();
        setPayments(paymentList);
    }, []);
    console.log(props.onUserUpdate)
    return <>
    <div className="screen profile">
            
        <Header user={props.user} onLogout={props.onLogout} showBack={true}/>
            
        <div className="screen-segment left">
            <div className="tab-spacer"></div>
            <Tabs user={props.user} onUserUpdate={props.onUserUpdate}  payments={payments}/>
            <div className="tab-spacer"></div>
        </div>
        
    </div>
    </>
}