import React, { Component } from 'react';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

// Components
import Header from './components/Header';
import { Footer } from './components/Footer';

// Screens
import { Welcome } from './screens/Welcome';
import { Login } from './screens/Login';
import { Home } from './screens/Home';
import { Profile } from './screens/Profile';
import { Payment } from './screens/Payment';

import { Route, withRouter, Switch } from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import { AuthorizationService } from './services/AuthorizationService';

import { createBrowserHistory } from "history";
import AddYourFriend from './screens/AddYourFriend';
import VerifyEmail from './screens/VerifyEmail';
import CreatePassword from './screens/CreatePassword';
import { PartnerIntro } from './screens/PartnerIntro';
import PartnerAddReferrals from './screens/PartnerAddReferrals';
import PartnerThankYou from './screens/PartnerThankYou';
import SelectSalesPerson from './screens/SelectSalesPerson';
const customHistory = createBrowserHistory();

let prevPath = null;
// listen and notify Segment of client-side page updates
customHistory.listen((location) => {
    if (location.hash !== prevPath) {
    prevPath = location.hash;
    window.analytics.page(location.hash);
  }
});

class App extends Component {

    constructor() {
        super();
        this.state = {};
    }

    async componentDidMount() 
    {
        this.authorizationService = new AuthorizationService();
        this.setState({
            isLoggedIn: this.authorizationService.isLoggedIn(),
            loggedInUser: this.authorizationService.loggedInUser
        })
    }

    onAuthenticatedUserUpdate(user){
        this.authorizationService.setLoggedInUser(user);
        //this.toast.show({severity: 'success', summary: 'Done!', detail: 'Profile information updated successfully.'});
        this.setState({
            isLoggedIn: this.authorizationService.isLoggedIn(),
            loggedInUser: this.authorizationService.loggedInUser
        });
    }

    onAuthenticated(user) {
        this.authorizationService.setLoggedInUser(user);
        console.log("user", user)
        if(user.active){
            this.props.history.push("/");
        }
        else {
            //this.props.history.push("/add-your-friends");
            this.props.history.push("/select-sales-person");
        }

        this.setState({
            isLoggedIn: user.active,
            loggedInUser: this.authorizationService.loggedInUser
        });

    }

    onSignedUp(user) {
        this.onAuthenticatedUserUpdate(user);
        this.props.history.push("/");
    }

    

    logout() {
        this.authorizationService.logout();
        this.setState({
            isLoggedIn: false,
            loggedInUser: null
        })
        this.props.history.push("/");
    }

    render() {
        const { isLoggedIn, loggedInUser } = this.state;
        const isPartner = window.location.pathname.startsWith("/partners");
        const isPartnerInternal = window.location.pathname.startsWith("/partners/") && (window.location.pathname.indexOf("/add-referrals") >= 0 || window.location.pathname.indexOf("/thank-you") >= 0);
        return (
        <div className={`App ${(loggedInUser && loggedInUser.active && !isPartner) || isPartnerInternal ? "internal" : "external"}`}>
            <Toast ref={(el) => this.toast = el} />
            { isLoggedIn && <>
                <Route path="/" exact component={() => <Home 
                    user={loggedInUser} 
                    onAuthenticated={this.onAuthenticated.bind(this)} 
                    onUserUpdate={this.onAuthenticatedUserUpdate.bind(this)}
                    onLogout={this.logout.bind(this)}/>} />

                <Route path="/profile" exact component={() => <Profile 
                    user={loggedInUser} 
                    onAuthenticated={this.onAuthenticated.bind(this)} 
                    onUserUpdate={this.onAuthenticatedUserUpdate.bind(this)}
                    onLogout={this.logout.bind(this)}/>}
                />

                <Route path="/payments/:id" component={() => <Payment user={loggedInUser}/>} />
            </>}
            { !isLoggedIn && <>
                <Route path="/" exact component={() => <Welcome onAuthenticated={this.onAuthenticated.bind(this)} />} />
                <Route path="/login" exact component={() => <Login onAuthenticated={this.onAuthenticated.bind(this)}/>} />                
                <Route path="/select-sales-person" exact component={() => <SelectSalesPerson user={loggedInUser}/>} />
                <Route path="/add-your-friends" exact component={() => <AddYourFriend user={loggedInUser}/>} />
                <Route path="/verify-email" exact component={() => <VerifyEmail user={loggedInUser}/>} />
                <Route path="/create-password" exact component={() => <CreatePassword user={loggedInUser} onSignedUp={this.onSignedUp.bind(this)} />} />

            </>}

            <Switch>
                <Route path="/partners/:school(add-referrals)" component={() => <PartnerAddReferrals/>} />
                <Route path="/partners/:school(thank-you)" component={() => <PartnerThankYou/>} />
                <Route path="/partners/:school/add-referrals" exact component={(args) => <PartnerAddReferrals school={args.match.params.school}/>} />
                <Route path="/partners/:school/thank-you" exact component={(args) => <PartnerThankYou school={args.match.params.school}/>} />                    
                <Route path="/partners/:school" component={(args) => <PartnerIntro school={args.match.params.school}/>} />
                <Route path="/partners" component={(args) => <PartnerIntro/>} />
            </Switch>
        </div>
        )
    }
}

export default withRouter(App);
