import { BaseService } from './_BaseService';

export class AuthorizationService extends BaseService {

    constructor(){
        super();
        const loggedInUserProp = localStorage.getItem(this.LOCAL_STORAGE_LOGGED_IN_USER_PROP) || "";
        this.loggedInUser = loggedInUserProp === "" && loggedInUserProp !== "undefined" ? null : JSON.parse(loggedInUserProp);
    }

    setLoggedInUser(_loggedInUser){
        this.loggedInUser = _loggedInUser;
        localStorage.setItem(this.LOCAL_STORAGE_LOGGED_IN_USER_PROP, JSON.stringify(_loggedInUser));
    }

    isLoggedIn(){
        return this.loggedInUser && this.loggedInUser.active ? true : false;
    }

    getCurrentUserId = () => {
        return this.loggedInUser.id;
    }

    checkEmail = async (name, email) => {
        return await this.post(`check-email`, { name, email });
    }

    login = async (username, password) => {
        return await this.post(`login`, { username, password });
    }

    logout = async () => {
        this.loggedInUser = null;
        localStorage.removeItem(this.LOCAL_STORAGE_LOGGED_IN_USER_PROP);
        localStorage.removeItem(this.LOCAL_STORAGE_AUTHORIZATION_TOKEN_PROP);
    }

    sendToken = async (email) => {
        return await this.post(`send-reset-token`, { email });
    }

    checkToken = async (token) => {
        return await this.post(`check-reset-token`, { token });
    }

    resetPassword = async (password) => {
        return await this.post(`reset-password`, { password });
    }
}