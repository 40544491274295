import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Password } from 'primereact/password';

import { AuthorizationService } from '../services/AuthorizationService';
import Header from '../components/Header';

export function Login(props) {

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authenticating, setAuthenticating] = useState(false);
    const [showWrongCredentialsMessage, setShowWrongCredentialsMessage] = useState(false);

    const [showPasswordRecover, setShowPasswordRecover] = useState(false);
    
    const [sendingCode, setSendingCode] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [checkingCode, setCheckingCode] = useState(false);
    const [codeChecked, setCodeChecked] = useState(false);
    
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [savingPassword, setSavingPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordReset, setPasswordReset] = useState(false);

    const login = async () => 
    {
        try 
        {
            setAuthenticating(true);
            setShowWrongCredentialsMessage(false);
            const authorizationService = new AuthorizationService();
            const user = await authorizationService.login(email, password);
            window.analytics.identify(user.email, {
                name: user.name,
                email: user.email,
            });
            props.onAuthenticated(user);
            setAuthenticating(false);
        } 
        catch (error) 
        {
            setAuthenticating(false);
            console.log(error);
            if(error.code && error.code === 401){
                setShowWrongCredentialsMessage(true);
            }
            else {
                alert(error.code ? error.code + " - " + error.message : error);
            }            
        }
    }

    const backToLogin = () => {
        setCode("");
        setCodeError(false)
        setCodeSent(false);
        setCodeChecked(false);
        setPasswordConfirmation("");
        setPasswordError(null);
        setShowPasswordRecover(false);
        setPassword("");
        setShowWrongCredentialsMessage(false);
    }

    const sendCode = async () => {
        try {
            setCode("");
            setCodeError(false);
            setSendingCode(true);
            const service = new AuthorizationService();
            await service.sendToken(email);
            setSendingCode(false);
            setCodeSent(true);    
        } 
        catch (error) 
        {
            setSendingCode(false);
            setCodeSent(false);    
            console.log(error);
            window.alert(error.message || error);
        }
        
    }

    const checkCode = async () => {
        
        try {
            setCodeError(false);
            setCheckingCode(true);
            const service = new AuthorizationService();
            await service.checkToken(code);
            setCheckingCode(false);
            setCodeChecked(true); 
        } 
        catch (error) 
        {
            console.log(error);
            setCheckingCode(false);
            setCodeChecked(false);    
            if(error.code && error.code === 400){
                setCodeError(true);
            }
            else {
                window.alert(error.message || error);
            }
        }
    }

    const resetPassword = async () => {
        setPasswordError(null)
        if(password !== passwordConfirmation){
            setPasswordError("Password confirmation does not match")
        }
        else {
            try {
                setSavingPassword(true);
                const service = new AuthorizationService();
                await service.resetPassword(password);
                setSavingPassword(false);
                setPasswordReset(true);
            } 
            catch (error) 
            {
                console.log(error);
                setSavingPassword(false);
                if(error.code && error.code === 400){
                    setPasswordError("Password is not complex enough. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters.");
                }
                else {
                    window.alert(error.message || error);
                }
            }
        }
    }

    return <>

        <div className="screen login">
            
            <Header user={props.user}/>

            <div className="screen-segment right mobile">
                    <div className="card green">
                        <div className="icon">
                            <img alt="Elevation Solar" src="/assets/layout/images/icon-logo-white.png"/>
                        </div>
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>
                    <div className="card yellow">
                        <div className="value">
                            <span>$</span>250s
                        </div>
                    </div>

            </div>

            <div className="screen-segment left">
                { !showPasswordRecover && <>
                <div className="title">
                    Share Elevation and <span className="bold">Get Paid</span>.
                </div>
                <div className="subtitle">
                    Share the power of smarter, cleaner energy by sharing your friends with Elevation. <a href="https://www.elevationsolar.com/refer-a-friend/" target="_blank">Learn More</a>.
                </div>
                </>}
                { !showPasswordRecover && <>
                    <div className="form-box">
                            <InputText 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your E-mail Address" 
                            />
                            <InputText 
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Your Password" 
                            />
                        
                    </div>
                    <div className="buttons">
                        <Button disabled={ email === "" || password === ""} label="Log In" className="button" onClick={login} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    { (authenticating) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                    { 
                        showWrongCredentialsMessage && 
                        <div className="fieldErrorMessage">Wrong credentials.</div>
                    }
                    <div className="footer-message">
                        Forgot your password? <span class="login" onClick={() => setShowPasswordRecover(true)}>Recover it</span>.<br/>
                        If you don't have an account, please <span class="login" onClick={() => history.push("/")}>Sign Up</span>.
                    </div>
                    
                </>}
                { showPasswordRecover && !codeSent &&
                    <>
                        <div className="title">What is your e-mail address?</div>
                        <div className="subtitle">We will send it a verification code.</div>
                        <div className="form-box">
                        <div className="formfield"style={{width: '100%'}}>
                            <InputText 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your E-mail Address" 
                                style={{width: '100%'}}
                            />
                        </div>
                        </div>
                        <div className="buttons">
                            <Button disabled={sendingCode || email === ""} label="Send verification code" className="button" onClick={sendCode} icon="pi pi-arrow-right" iconPos="right"/>
                        </div>
                        { sendingCode && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                        <div className="footer-message">
                            Have you remembered your password? <span class="login" onClick={() => backToLogin()}>Back to Login</span>.
                        </div>
                    </>
                }
                { showPasswordRecover && codeSent && !codeChecked && 
                    <>
                        <div className="title">We've sent you a verification code</div>
                        <div className="subtitle">Please check you inbox, fill the received code on the field below and click "Verify".</div>
                        <div className="form-box">
                        <div className="formfield"style={{width: '100%'}}>
                            <InputText 
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="Verification Code" 
                                style={{width: '100%'}}
                            />
                        </div>
                        </div>
                        { codeError && <div className="fieldErrorMessage">Invalid verification code</div> }
                        <div className="buttons">    
                            <Button disabled={sendingCode || checkingCode} label="Verify" className="button" onClick={checkCode} icon="pi pi-arrow-right" iconPos="right"/>
                        </div>
                        { (sendingCode || checkingCode) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                        <div className="footer-message">
                            Didn't get the code? <span class="login" onClick={() => sendCode()}>Send it again</span>.<br/>
                            Have you remembered your password? <span class="login" onClick={() => backToLogin()}>Back to Login</span>.
                        </div>
                    </>
                }
                { showPasswordRecover && codeChecked && !passwordReset && 
                    <>
                        <div className="title">Verification successful</div>
                        <div className="subtitle">Please create your new password.</div>
                        <div className="form-box">
                            <div className="formfield" style={{width: '328px', marginRight: '17px'}}>
                                <Password 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    placeholder="Your Password"
                                    style={{width: '100%'}}
                                    inputStyle={{width: '100%'}}
                                    promptLabel="Password must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                    weakLabel="Password is too weak. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                    mediumLabel="Password is not complex enough yet. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                    strongLabel="We are good to go."
                                    toggleMask={true}
                                />
                            </div>
                            <div className="formfield" style={{width: '328px'}}>
                                <Password 
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    placeholder="Confirm It" 
                                    feedback={false}
                                    toggleMask={true}
                                    style={{width: '100%'}}
                                    inputStyle={{width: '100%'}}
                                />
                            </div>
                        </div>
                        <div className="buttons" style={{marginTop: '30px'}}>    
                            <Button disabled={savingPassword} label="Save" className="button" onClick={resetPassword} icon="pi pi-arrow-right" iconPos="right"/>
                        </div>
                        { passwordError && <div className="referral-error">{passwordError}</div> }
                        { (savingPassword) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                        <div className="footer-message">
                            Have you remembered your password? <span class="login" onClick={() => backToLogin()}>Back to Login</span>.
                        </div>
                    </>
                }
                { showPasswordRecover && passwordReset && 
                    <>
                        <div className="title">Password reset successfully.</div>
                        <div className="buttons">    
                            <Button label="Back to Login" className="button" onClick={backToLogin} icon="pi pi-arrow-right" iconPos="right"/>
                        </div>
                    </>
                }
            </div>
            <div className="screen-segment right desktop">
                    <div className="card green">
                        <div className="icon">
                            <img alt="Elevation Solar" src="/assets/layout/images/icon-logo-white.png"/>
                        </div>
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>
                    <div className="card yellow">
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>

            </div>
        </div>
    </>
}