import React, { useState, useEffect, useRef } from 'react';

import { withRouter, useHistory } from "react-router";

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

function Header(props) {

    const history = useHistory();
    const menu = useRef(null);
    const [user, setUser] = useState(props.user);
    
    useEffect(() => {
        setUser(props.user);
    });

    const logout = () => {
        props.onLogout();
        setUser(null);
    }

    let items = [];

    if(user && user.active)
    {
        items = [
            {
                label: 'Profile',
                items: [
                    { 
                        label: 'My Referrals', 
                        icon: 'pi pi-users',
                        command:()=> { 
                            { window.location.hash="/"; }
                        }
                    },
                    { 
                        label: 'My Profile', 
                        icon: 'pi pi-pencil',
                        command:()=> { 
                            { window.location.hash="/profile"; }
                        }
                    }
                ]
            },
            {
                separator:true
            },
            {
                label: 'Log Out', 
                icon: 'pi pi-sign-out', 
                command: logout
            }
        ]
    }
    else {
        items = [
            {
                label: 'Log Out', 
                icon: 'pi pi-sign-out', 
                command: logout
            }
        ]
    }

    return <>
        <div className="header">
            <div className='logo'>
                <img alt="Elevation Solar" src={(user && user.active) || props.darkHeader ? "/assets/layout/images/logo-black.png" : "/assets/layout/images/logo-white.png"}/>
                { false && props.schoolLogo && props.schoolLogo !== "" && 
                    <img alt="Elevation Solar" src={"/assets/layout/images/" + props.schoolLogo} height="50" style={{ marginLeft: "50px"}}/>
                }
            </div>
            
            { 
                user && <>
                <div className='user-info'>
                    <div className="name-and-logout">
                        <div className="name">Hello <span>{user.name}</span></div>
                        <div className="logout" onClick={logout}>log out</div>
                    </div>
                    <img alt="Elevation Solar" src="/assets/layout/images/person-icon.png"/>
                </div>
                { props.showBack && 
                    <Button label="Back" className="button" onClick={() => history.push("/")}  icon="pi pi-arrow-left" iconPos="left"/>
                }
                <div className='cogs'>
                    <img alt={user.name} src="/assets/layout/images/icon-cog.png" onClick={() => history.push("/profile")}/>
                </div>
                </>
            }
        </div>
    </>;
}

export default withRouter(Header);