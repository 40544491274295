import { useState, useRef, useEffect } from "react";
import { withRouter, useHistory } from "react-router";

import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { ProgressBar } from "primereact/progressbar";

import Header from "../components/Header";
import OnboardingMenu from "../components/OnboardingMenu";

import * as _ from 'lodash';
import { AuthorizationService } from "../services/AuthorizationService";

import { Dropdown } from 'primereact/dropdown';
import { EnergyConsultantService } from "../services/EnergyConsultantService";


function SelectSalesPerson(props){

    const history = useHistory();
    const toast = useRef(null);

    const [ecs, setEcs] = useState([]);

    const [processing, setProcessing] = useState(true);
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState({});

    const initialLoad = async () => {
        const authorizationService = new AuthorizationService();
        setUser(authorizationService.loggedInUser);
        const energyConsultantService = new EnergyConsultantService();
        const _ecs = await energyConsultantService.list();
        setEcs(_ecs.map(ec => ({ label: `${ec.name} (${ec.email})`, value: ec.email})));
        setProcessing(false);
    }

    useEffect(() => {        
        initialLoad()
    }, []);

    const selectEnergyConsultant = async (ec_email) => {
        console.log(ec_email);
        const _user = Object.assign({}, user);
        _user.energyConsultant = ec_email;
        setUser(_user);
    }
    const skip = async () => {
        props.history.push("/add-your-friends");
    }

    const save = async () => {
        try 
        {
            setSaving(true);
            const authorizationService = new AuthorizationService();
            authorizationService.setLoggedInUser(user);
            setSaving(false);
            history.push("/add-your-friends") ;
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            toast.current.show({severity: 'error', summary: 'Error', detail: error.toString()});
        }
    }
    return <>
        <div className="split-screen-background">
            <div className="left"></div>
            <div className="right"></div>
        </div>
        
        <Toast ref={toast} />

        <div className="screen onboarding add-your-friend">

            <Header/>

            <div className="screen-segment left">
                <OnboardingMenu step={1}/>
            </div>

            <div className="steps-progreess-bar" style={{width: '33%'}}></div>

            <div className="screen-segment right">
                <div className="form">
                    <div className="text">Select the sales person that contacted you. You can search by his/her name or email address.</div>
                    
                    <div className="item">
                        <div className="item-line">
                            <Dropdown disabled={processing} filter showClear value={user.energyConsultant} options={ecs} onChange={(e) => selectEnergyConsultant(e.value)} placeholder={processing ? "Loading list..." : "Select a sales person"}/>
                        </div>
                    </div>
                    
                    <div className="buttons">
                        <Button disabled={(user.energyConsultant || "") === ""} label="Select and Continue" className="button" onClick={save} icon="pi pi-arrow-right" iconPos="right"/>
                        { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                    </div>

                    <div className="skip" onClick={saving ? () => {} : skip}>Skip this step</div>
                    { (processing) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                </div>
            </div>

        </div>
    </>;
}

export default withRouter(SelectSalesPerson);