import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { ProgressBar } from 'primereact/progressbar';

import { AuthorizationService } from '../services/AuthorizationService';
import Header from '../components/Header';


export function Welcome(props) {

    const history = useHistory();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [fromSalesInvite, setFromSalesInvite] = useState("N");
    const [energyConsultant, setEnergyConsultant] = useState(null);
    const [showAccountAlreadyActiveMessage, setShowAccountAlreadyActiveMessage] = useState(false);
    const [saving, setSaving] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    useEffect(async () => {

        localStorage.removeItem("_name");
        localStorage.removeItem("_email");
        localStorage.removeItem("_sale");
        
        if(window.location.search)
        {
            const parameters = window.location.search.split("?")[1].split("&").map(pstr => ({key: pstr.split("=")[0], value: pstr.split("=")[1]}));
            await localStorage.setItem("_name", decodeURI((parameters.find(p => p.key === 'name') || {}).value || ""));
            await localStorage.setItem("_email", decodeURI((parameters.find(p => p.key === 'email') || {}).value || ""));
            await localStorage.setItem("_sale", decodeURI((parameters.find(p => p.key === 'sale') || {}).value || "N"));
            await localStorage.setItem("_ec", decodeURI((parameters.find(p => p.key === 'ec') || {}).value));
            window.location.search = "";
        }
        
        if((localStorage.getItem("_name") || "") !== ""){
            setName("" + localStorage.getItem("_name"));
        }
        
        if((localStorage.getItem("_email") || "") !== ""){
            setEmail(localStorage.getItem("_email"));
        }

        if((localStorage.getItem("_sale") || "") !== ""){
            setFromSalesInvite(localStorage.getItem("_sale"));
        }

        console.log("_ec", localStorage.getItem("_ec"));
        if((localStorage.getItem("_ec") || "") !== ""){
            console.log("From EC Virtual Business Card")
            setEnergyConsultant(localStorage.getItem("_ec"));
        }
        else {
            setEnergyConsultant(null);
        }

    }, []);

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }

    const checkEmail = async () => 
    {
        try 
        {
            localStorage.removeItem("_name");
            localStorage.removeItem("_email");
            localStorage.removeItem("_sale");
            localStorage.removeItem("_ec");

            setInvalidEmail(false);
            if(!isValidEmail(email)){
                setInvalidEmail(true);
            }
            else {
                setSaving(true);
                const authorizationService = new AuthorizationService();
                const user = await authorizationService.checkEmail(name, email);
                user.fromSalesInvite = fromSalesInvite;
                user.energyConsultant = energyConsultant;
                window.analytics.identify(user.email, {
                    name: user.name,
                    email: user.email,
                });
                
                console.log("checkEmail", user);
                props.onAuthenticated(user);
                setSaving(false);
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            if(error.code && error.code === 430){
                setShowAccountAlreadyActiveMessage(true);
            }
            else {
                alert(error);
            }            
        }
    }

    return <>
    
        <div className="screen welcome">
            
            <Header user={props.user}/>

            <div className="screen-segment right mobile">
                    <div className="card green">
                        <div className="icon">
                            <img alt="Elevation Solar" src="/assets/layout/images/icon-logo-white.png"/>
                        </div>
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>
                    <div className="card yellow">
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>

            </div>
            
            <div className="screen-segment left">
                <div className="title">
                    Share Elevation and <span className="bold">Get Paid</span>.
                </div>
                <div className="subtitle">
                    Share the power of smarter, cleaner energy by sharing your friends with Elevation. <a href="https://www.elevationsolar.com/refer-a-friend/" target="_blank">Learn More</a>.
                </div>
                <div className="form-box">
                    <InputText 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your Name" 
                        />
                        <InputText 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your E-mail Address" 
                        />
                    
                </div>
                <div className="buttons">
                    <Button disabled={name === "" || email === ""} label="Create Your Account" className="button" onClick={checkEmail} 
                    icon="pi pi-arrow-right" iconPos="right"/>
                </div>
                { 
                    saving && 
                    <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" /> 
                
                }
                { 
                    showAccountAlreadyActiveMessage && 
                    <div className="fieldErrorMessage">An active account is already using this email address. Please&nbsp;<span class="login" onClick={() => history.push("/login")}>log in</span>.</div>
                }
                { 
                    invalidEmail && 
                    <div className="fieldErrorMessage">Please provide a valid email address.</div>
                }
                <div className="footer-message">
                    Have an account? <span className="login" onClick={() => history.push("/login")}>Log in</span>. | <a href="https://www.poweredbyelevation.com/terms" target="_blank">Terms and Conditions</a>
                </div>
            </div>

            <div className="screen-segment right desktop">
                    <div className="card green">
                        <div className="icon">
                            <img alt="Elevation Solar" src="/assets/layout/images/icon-logo-white.png"/>
                        </div>
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>
                    <div className="card yellow">
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>

            </div>
            
        </div>
    </>
}