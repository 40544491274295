import { useState, useRef, useEffect } from "react";
import { withRouter, useHistory } from "react-router";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { ProgressBar } from "primereact/progressbar";
import { InputMask } from 'primereact/inputmask';

import Header from "../components/Header";
import OnboardingMenu from "../components/OnboardingMenu";
import { SignUpService } from "../services/SignUpService";

import * as _ from 'lodash';
import * as uuid from 'uuid';
import { ReferralService } from "../services/ReferralService";
import { AuthorizationService } from "../services/AuthorizationService";


function AddYourFriend(props){

    const history = useHistory();
    const toast = useRef(null);

    const referralTemplate = {
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        status: ""
    };

    const [processing, setProcessing] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [referrals, setReferrals] = useState([Object.assign({id: uuid.v4(), new: true}, referralTemplate)]);
    const [usedCount, setUsedCount] = useState(0);
    const [user, setUser] = useState({});

    useEffect(async () => {        
        const authorizationService = new AuthorizationService();
        setUser(authorizationService.loggedInUser);
    }, []);

    const add = () => {
        setReferrals([].concat(referrals).concat([Object.assign({id: uuid.v4(), new: true}, referralTemplate)]));
    }

    const isUsed = (referral) => {
        return referral.first_name !== "" || referral.last_name !== "" || referral.mobile !== "" || referral.email !== "";
    }

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }

    const updateValue = (referral, fieldName, value) => {
        const _referrals  = [].concat(referrals);
        _.set(_referrals.find(r => r.id === referral.id), fieldName, value);
        setReferrals(_referrals);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const sendCode = async () => {
        try {
            setProcessing(true);
            const service = new SignUpService();
            await service.sendToken(user.name, user.email);
            setProcessing(false);
            history.push("/verify-email?skip=1") 
        } 
        catch (error) 
        {
            setProcessing(false);
            console.log(error);
            toast.current.show({severity:'error', summary: 'Ooops...', detail: error.message || error.toString(), life: 3000});
        }
        
    }

    const skip = async () => {
        await sendCode();
    }

    const isDataValid = () => {
        let _used = 0;
        for(let i = 0; i < referrals.length; i++){
            if(isUsed(referrals[i])){
                _used++;
                if(referrals[i].first_name === "" || referrals[i].last_name === "" || referrals[i].mobile === "" || (referrals[i].email !== "" & !isValidEmail(referrals[i].email))){
                    return false;
                }
    
                if(referrals.find(r => r.mobile !== "" && cleanPhone(r.mobile) === cleanPhone(referrals[i].mobile) && r.id !== referrals[i].id)){
                    return false;
                }
    
                if(referrals.find(r => r.email !== "" && r.email === referrals[i].email && r.id !== referrals[i].id)){
                    return false;
                }
            }
        }

        setUsedCount(_used);
        if(_used === 0){
            return false;
        }

        return true;
    }

    const save = async () => {
        try 
        {
            setSubmitted(true);
            if(isDataValid())
            {
                setSaving(true);
                const service = new ReferralService();
                setReferrals(await service.batchSave(referrals, user.energyConsultant));
                setSaving(false);
                history.push("/verify-email") 
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            if(error.code && error.code === 400){
                setReferrals(error.message.referrals);
            }
            else {
                alert(error);
            }            
        }
    }
    return <>
        <div className="split-screen-background">
            <div className="left"></div>
            <div className="right"></div>
        </div>
        
        <Toast ref={toast} />

        <div className="screen onboarding add-your-friend">

            <Header/>

            <div className="screen-segment left">
                <OnboardingMenu step={2}/>
            </div>

            <div className="steps-progreess-bar" style={{width: '33%'}}></div>

            <div className="screen-segment right">
                <div className="form">
                    <div className="text">Enter your friend’s name, mobile phone and e-mail address to refer them to Elevation. Click Add Another Friend to refer multiple friends at once. You can also skip this step and add referrals later.</div>
                    
                    { 
                        referrals.map(referral =>

                        
                        
                            <div key={referral.id} className="item">
                                <div className="item-line">
                                    <InputText 
                                        disabled={saving}
                                        value={referral.first_name || ""} 
                                        onChange={(e) => updateValue(referral, 'first_name', e.target.value)} 
                                        placeholder="First Name"
                                    />
                                    <InputText 
                                        disabled={saving}
                                        value={referral.last_name || ""} 
                                        onChange={(e) => updateValue(referral, 'last_name', e.target.value)} 
                                        placeholder="Last Name" 
                                    />
                                </div>
                                <div className="item-line">
                                    <InputMask 
                                        disabled={saving}
                                        autoClear={false} 
                                        mask="(999) 999-9999" 
                                        value={referral.mobile || ""} 
                                        placeholder="Mobile Phone #" 
                                        unmask={true} 
                                        onChange={(e) => updateValue(referral, 'mobile', cleanPhone(e.value))}
                                    />
                                    <InputText 
                                        disabled={saving}
                                        value={referral.email || ""} 
                                        onChange={(e) => updateValue(referral, 'email', e.target.value)} 
                                        placeholder="E-Mail Address" 
                                    />
                                </div>

                                { submitted && isUsed(referral) && referral.first_name === "" && <div className="referral-error">First name is mandatory</div>}

                                { submitted && isUsed(referral) && referral.last_name === "" && <div className="referral-error">Last name is mandatory</div>}

                                { (referral.error || "") === "PHONE_ALREADY_REFERRED" && <div className="referral-error">Mobile number was already referred</div>}
                                { submitted && isUsed(referral) && referral.mobile === "" && <div className="referral-error">Mobile is mandatory</div>}
                                { isUsed(referral) && referrals.find(r => r.mobile !== "" && cleanPhone(r.mobile) === cleanPhone(referral.mobile) && r.id !== referral.id) && <div className="referral-error">Duplicate mobile number</div> }

                                { (referral.error || "") === "EMAIL_ALREADY_REFERRED" && <div className="referral-error">Email was already referred</div>}
                                { submitted && isUsed(referral) && referral.email !== "" && !isValidEmail(referral.email) && <div className="referral-error">Email is invalid</div>}
                                { isUsed(referral) && referrals.find(r => r.email !== "" && r.email === referral.email && r.id !== referral.id) && <div className="referral-error">Duplicate email address</div> }
                            </div>
                        )
                    }
                    
                    <div className="add-another" onClick={saving ? () => {} : add} disabled={saving}>+ Add Another Friend</div>
                    
                    <div className="buttons">
                        <Button disabled={saving} label="Submit and Continue" className="button" onClick={save} icon="pi pi-arrow-right" iconPos="right"/>
                        { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                        { submitted && usedCount === 0 && <div className="referral-error">Please add at least one referral, or skip.</div>}
                    </div>

                    <div className="skip" onClick={saving ? () => {} : skip}>Skip this step</div>
                    { (processing) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                    
                </div>
            </div>

        </div>
    </>;
}

export default withRouter(AddYourFriend);