import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';

import Header from '../components/Header';


export function PartnerIntro(props) {

    const history = useHistory();

    const schools = [
        {
            id: "newellbarney",
            name: "Newell Barney Junior High School",
            logo: "newell_barney.png"
        },
        {
            id: "fuentedevida",
            name: "Fuente de Vida",
            logo: "newell_barney.png"
        }
    ]

    //Other_Sub_Lead_Source__c

    const go = async () => 
    {
        try 
        {
            history.push(`/partners/${ props.school ? `${props.school}/` : ""}add-referrals`); 
        } 
        catch (error) 
        {
            window.alert(error);
        }
    }

    return <>
    
        <div className="screen partner-intro">
            
            <Header user={props.user} schoolLogo={props.school && schools.find(s => s.id === props.school) ? schools.find(s => s.id === props.school).logo : ""}/>

            <div className="screen-segment right mobile">
                    <div className="card green">
                        <div className="icon">
                            <img alt="Elevation Solar" src="/assets/layout/images/icon-logo-white.png"/>
                        </div>
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>
                    <div className="card yellow">
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>

            </div>
            
            <div className="screen-segment left">
                { props.school && schools.find(s => s.id === props.school) ?
                    <>
                    <div className="title">
                        Share Elevation and Raise Money for <span className="bold">{schools.find(s => s.id === props.school).name}</span>.
                    </div>
                    <div className="subtitle">
                        No-commitment consultation to see how you and your family can benefit from Elevation's home energy solutions. 
                        <br/>Elevation will donate $250 per consultation and $250 per sale. 
                    </div>
                    <div className="buttons">
                        <Button label="Add Referrals" className="button" onClick={go} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    
                    <div className="footer-message">
                        <a href="https://www.poweredbyelevation.com/terms" target="_blank">Terms and Conditions</a>
                    </div>
                    </>
                    :
                    <>
                    <div className="title">
                        Share Elevation and Raise Money for your organization.
                    </div>
                    <div className="subtitle">
                        Sign up for a 100% free, no-commitment consultation to see how you and your family can benefit from smarter, cleaner solar energy and many of our other energy solutions. Share with your community to earn even more donations.
                    </div>
                    <div className="buttons">
                        <Button label="Sign Up" className="button" onClick={go} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    
                    <div className="footer-message">
                        <a href="https://www.poweredbyelevation.com/terms" target="_blank">Terms and Conditions</a>
                    </div>
                    </>
                }
                
            </div>

            <div className="screen-segment right desktop">
                    <div className="card green">
                        <div className="icon">
                            <img alt="Elevation Solar" src="/assets/layout/images/icon-logo-white.png"/>
                        </div>
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>
                    <div className="card yellow">
                        <div className="value">
                            <span>$</span>250
                        </div>
                    </div>

            </div>
            
        </div>
    </>
}